// extracted by mini-css-extract-plugin
export var alignDiscLeft = "t_rr d_fp d_bG d_dv";
export var alignLeft = "t_qh d_fp d_bG d_dv";
export var alignDiscCenter = "t_rs d_fq d_bD d_dw";
export var alignCenter = "t_bP d_fq d_bD d_dw";
export var alignDiscRight = "t_rt d_fr d_bH d_dx";
export var alignRight = "t_qj d_fr d_bH d_dx";
export var footerContainer = "t_rv d_dW d_bW";
export var footerContainerFull = "t_rw d_dT d_bW";
export var footerHeader = "t_kf d_kf";
export var footerTextWrapper = "t_rx d_w";
export var footerDisclaimerWrapper = "t_km d_km d_ck";
export var badgeWrapper = "t_ry d_w d_bz d_bP d_bF d_bJ";
export var verticalTop = "t_rz d_bz d_bJ d_bN d_bL";
export var wide = "t_rB d_cy";
export var right = "t_rC d_bK";
export var line = "t_fk d_fl d_cv";
export var badgeDisclaimer = "t_rD d_bC d_bP d_bJ";
export var badgeContainer = "t_rF d_bz d_bH d_bP";
export var badge = "t_rG";
export var padding = "t_rH d_c7";
export var end = "t_rJ d_bH";
export var linkWrapper = "t_rK d_dB";
export var link = "t_mC d_dB";
export var colWrapper = "t_rL d_cx";
export var consent = "t_f d_f d_bC d_bP";
export var disclaimer = "t_rM d_bz d_bJ";
export var media = "t_rN d_bx d_dy";
export var itemRight = "t_rP";
export var itemLeft = "t_rQ";
export var itemCenter = "t_rR";
export var exceptionWeight = "t_rS y_sP";