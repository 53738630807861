// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "w_r1 d_gv d_cs";
export var heroHeaderCenter = "w_gw d_gw d_cs d_dw";
export var heroHeaderRight = "w_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "w_r2 d_gr d_cw";
export var heroParagraphCenter = "w_gs d_gs d_cw d_dw";
export var heroParagraphRight = "w_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "w_r3 d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "w_r4 d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "w_r5 d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "w_r6 d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "w_r7 d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "w_r8 y_r8";
export var heroExceptionNormal = "w_r9 y_r9";
export var heroExceptionLarge = "w_sb y_sb";
export var Title1Small = "w_sc y_sc y_sm y_sn";
export var Title1Normal = "w_sd y_sd y_sm y_sp";
export var Title1Large = "w_sf y_sf y_sm y_sq";
export var BodySmall = "w_sg y_sg y_sm y_sG";
export var BodyNormal = "w_sh y_sh y_sm y_sH";
export var BodyLarge = "w_sj y_sj y_sm y_sJ";